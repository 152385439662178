import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/MainView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue'),
  },
  {
    path: '/why_us',
    name: 'why_us',
    component: () => import('@/views/WhyUsView.vue'),
  },
  {
    path: '/trusted_by',
    name: 'trusted_by',
    component: () => import('@/views/TrustedByView.vue'),
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('@/views/DeliveryView.vue'),
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/ContactsView.vue'),
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('@/views/CatalogView.vue'),
  },
  {
    path: '/category/:categoryId',
    name: 'category',
    component: () => import('@/views/SubcategoriesView.vue'),
  },
  {
    path: '/:parentId/products',
    name: 'products',
    component: () => import('@/views/ProductsList.vue'),
  },
  {
    path: '/:parentId/products/:productId',
    name: 'product',
    component: () => import('@/views/ProductView.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/AdminView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
