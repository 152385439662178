import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueTheMask from 'vue-the-mask';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


// Vuetify
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'leaflet/dist/leaflet.css';


const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);
app.use(VueTheMask);

app.mount('#app');
